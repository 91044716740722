import { ValueOf } from '../../types'
import { swapKeyValue } from '../../utils'

/**
 * 専有面積基準区分
 */
export const Enum = { 内法: '1', 壁芯: '2' } as const

export type Enum = ValueOf<typeof Enum>
const labelMap = swapKeyValue(Enum)

export const getLabel = (value: Enum) => labelMap[value]
