import { PostMiraieV1Import } from '../miraie/PostMiraieV1Import'
import { miraieFields } from '../miraie/fields'
import { getYmd } from '../utils/date'

import { MediaPublishRequestForTeraSeven } from './MediaPublishRequestForTeraSeven'

export function baikaiShinseiToPostMiraie(
  shinsei: MediaPublishRequestForTeraSeven,
): Omit<PostMiraieV1Import, 'bk_state_kbn'> {
  const {
    Contract__r: {
      name,
      bk_sbt_kbn,
      bk_item_kbn,
      mediationCategory,
      price,
      mansionFloor,
    },
    ...rest
  } = shinsei

  const right = rest.Rights__c.at(0)
  // 用途地域、建物構造はtera-three側では何個でも選べるがみらいえでは2つまでしか指定できないので、はじめの2つを使う
  const [yoto1, yoto2] = rest.Placement__c
  const [kozo1, kozo2] = rest.BuildingStructure__c
    ? rest.BuildingStructure__c
    : [undefined, undefined]
  const chikuYmd = rest.AgeOfBuilding__c
    ? getYmd(new Date(rest.AgeOfBuilding__c))
    : undefined
  const hikiwatashiYmd = rest.ExtraditionPeriod__c
    ? getYmd(new Date(rest.ExtraditionPeriod__c))
    : undefined

  return {
    bk_sbt_kbn,
    bk_item_kbn,
    ...(name != null && { tatemono_name: name }),
    ...(mansionFloor != null && { shozai_kai: mansionFloor }),
    torihiki_kbn: mediationCategory,
    price: miraieFields.price.convertToMiraiePrice(
      price,
      miraieFields.bk_sbt_kbn.isChintai(bk_sbt_kbn),
    ),
    genkyo_kbn:
      miraieFields.genkyo_kbn.T3MapBaikai[rest.PropertyCurrentStatus__c],
    ...(right && {
      tochi_pow_kbn: miraieFields.tochi_pow_kbn.T3MapBaikai[right],
    }),
    ...(yoto1 && {
      yoto_chiki_kbn_1: miraieFields.yoto_chiki_kbn.T3MapBaikai[yoto1],
    }),
    ...(yoto2 && {
      yoto_chiki_kbn_2: miraieFields.yoto_chiki_kbn.T3MapBaikai[yoto2],
    }),
    ...(kozo1 && {
      tate_kozo_kbn: miraieFields.tate_kozo_kbn.T3MapBaikai[kozo1],
    }),
    ...(kozo2 && {
      tate_ichibu_kozo_kbn:
        miraieFields.tate_ichibu_kozo_kbn.T3MapBaikai[kozo2],
    }),
    ...(rest.CatchCopy__c !== null && { bk_catch: rest.CatchCopy__c }),
    ...(rest.SubCatchCopy__c !== null && { net_com: rest.SubCatchCopy__c }),
    ...(rest.BalconyPatioArea__c !== null && {
      bal_menseki: rest.BalconyPatioArea__c,
    }),
    ...(chikuYmd
      ? {
          chiku_ymd_year: chikuYmd.year.toString(),
          chiku_ymd_month: chikuYmd.month.toString(),
        }
      : {}),
    ...(hikiwatashiYmd
      ? {
          hikiwatashi_ymd_year: hikiwatashiYmd.year.toString(),
          hikiwatashi_ymd_month: hikiwatashiYmd.month.toString(),
        }
      : {}),
    ...(rest.ConstructionCompany__c !== null && {
      seko_company: rest.ConstructionCompany__c,
    }),
    ...(rest.MonthlyAdministrativeExpenses__c !== null && {
      kanrihi: rest.MonthlyAdministrativeExpenses__c,
    }),
    ...(rest.MonthlyRepairReserveAmount__c !== null && {
      tsumitatekin: rest.MonthlyRepairReserveAmount__c,
    }),
    ...(rest.ManagementForm__c !== null && {
      manage_hoshiki_kbn:
        miraieFields.manage_hoshiki_kbn.T3MapBaikai[rest.ManagementForm__c],
    }),
  }
}
