import { ValueOf } from '../../types'
import { swapKeyValue } from '../../utils'

/**
 * 引渡年月日旬区分
 */
export const Enum = { 上旬: '1', 中旬: '2', 下旬: '3' } as const

export type Enum = ValueOf<typeof Enum>

const labelMap = swapKeyValue(Enum)

export const getLabel = (value: Enum) => labelMap[value]
