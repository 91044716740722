import { ValueOf } from '../../types'
import { swapKeyValue } from '../../utils'

/**
 * 町会費区分
 */
export const Enum = { 町会費: '1', 町内会費: '2', 自治会費: '3' } as const

export type Enum = ValueOf<typeof Enum>

const labelMap = swapKeyValue(Enum)

export const getLabel = (value: Enum) => labelMap[value]
