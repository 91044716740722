import { ValueOf } from '../../types'
import { swapKeyValue } from '../../utils'

/**
 * バイク置場料金区分
 */
export const Enum = { 月: '1', 半年: '2', 年: '3' } as const

export type Enum = ValueOf<typeof Enum>

const labelMap = swapKeyValue(Enum)

export const getLabel = (value: Enum) => labelMap[value]
