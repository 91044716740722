import { miraieFields } from '@terass/common/src'
import { V1Import } from '@terass/common/src/firestore/V1Import'
import { PostMiraieV1Import } from '@terass/common/src/miraie/PostMiraieV1Import'
import {
  UpdateData,
  addDoc,
  doc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore'

import { getCollection } from './firestore'

import { publishMediaCoverage } from '@/utils/functions'

const defaultFormData = {
  suumo_c_open_kbn: miraieFields.c_open_kbn.Enum.非掲載,
  homes_c_open_kbn: miraieFields.c_open_kbn.Enum.非掲載,
  athome_c_open_kbn: miraieFields.c_open_kbn.Enum.非掲載,
  rakumachi_c_open_kbn: miraieFields.c_open_kbn.Enum.非掲載,
}

const fixedFormData = {
  bk_state_kbn: miraieFields.bk_state_kbn.Enum.公開中,
  suumonet_keisai_kbn: miraieFields.suumonet_keisai_kbn.Enum.SUUMOネットコマ,
}

export const createImport = async ({
  formData,
  ...data
}: Omit<
  V1Import,
  | 'updatedAt'
  | 'createdAt'
  | 'status'
  | 'draftedAt'
  | 'publishedAt'
  | 'closedAt'
  | 'formData'
  | 'applyingFormData'
> & {
  formData: Omit<PostMiraieV1Import, 'bk_state_kbn'>
}) => {
  const initialFormData: PostMiraieV1Import = {
    ...defaultFormData,
    ...formData,
    ...fixedFormData,
    ...(formData.sakimono_flg === miraieFields.sakimono_flg.Enum.先物 && {
      kyakutsuke_pass_flg: miraieFields.kyakutsuke_pass_flg.Enum.不可,
    }),
  }
  const { id } = await addDoc(getCollection('v1import'), {
    ...data,
    formData: initialFormData,
    applyingFormData: initialFormData,
    updatedAt: serverTimestamp(),
    createdAt: serverTimestamp(),
    status: 'draft',
    draftedAt: serverTimestamp(),
    publishedAt: null,
    closedAt: null,
  })
  await publishMediaCoverage({ importId: id })
  return { id }
}

export const updateImport = (
  importId: string,
  data: UpdateData<Omit<V1Import, 'updatedAt' | 'createdAt'>>,
) =>
  updateDoc(doc(getCollection('v1import'), importId), {
    ...data,
    updatedAt: serverTimestamp(),
  })
