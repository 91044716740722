import { ValueOf } from '../../types'
import { swapKeyValue } from '../../utils'

/**
 * 引渡区分
 */
export const Enum = {
  即時: '1',
  相談: '2',
  指定有: '3',
  予定: '4',
  契約後引渡: '5',
} as const

export type Enum = ValueOf<typeof Enum>

const labelMap = swapKeyValue(Enum)

export const getLabel = (value: Enum) => labelMap[value]
